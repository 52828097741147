const Time = [
    {
        id: '01',
        date: "2023-03-24",
        day: "Friday",
        sehriHour: "04",
        sehriMinutes: 39,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 14,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '02',
        date: "2023-03-25",
        day: "Saturday",
        sehriHour: '04',
        sehriMinutes: 38,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 15,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '03',
        date: "2023-03-26",
        day: "Sunday",
        sehriHour: '04',
        sehriMinutes: 36,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 15,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '04',
        date: "2023-03-27",
        day: "Monday",
        sehriHour: '04',
        sehriMinutes: 35,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 16,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '05',
        date: "2023-03-28",
        day: "Tuesday",
        sehriHour: '04',
        sehriMinutes: 34,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 16,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '06',
        date: "2023-03-29",
        day: "Wednesday",
        sehriHour: '04',
        sehriMinutes: 32,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 17,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '07',
        date: "2023-03-30",
        day: "Thursday",
        sehriHour: '04',
        sehriMinutes: 31,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 17,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '08',
        date: "2023-03-31",
        day: "Friday",
        sehriHour: '04',
        sehriMinutes: 30,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 18,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '09',
        date: "2023-04-01",
        day: "Saturday",
        sehriHour: '04',
        sehriMinutes: 29,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 18,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '10',
        date: "2023-04-02",
        day: "Sunday",
        sehriHour: '04',
        sehriMinutes: 28,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 19,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '11',
        date: "2023-04-03",
        day: "Monday",
        sehriHour: '04',
        sehriMinutes: 27,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 19,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '12',
        date: "2023-04-04",
        day: "Tuesday",
        sehriHour: '04',
        sehriMinutes: 26,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 19,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '13',
        date: "2023-04-05",
        day: "Wednesday",
        sehriHour: '04',
        sehriMinutes: 24,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 20,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '14',
        date: "2023-04-06",
        day: "Thursday",
        sehriHour: '04',
        sehriMinutes: 24,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 20,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '15',
        date: "2023-04-07",
        day: "Friday",
        sehriHour: '04',
        sehriMinutes: 23,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 21,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '16',
        date: "2023-04-08",
        day: "Saturday",
        sehriHour: '04',
        sehriMinutes: 22,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 21,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '17',
        date: "2023-04-09",
        day: "Sunday",
        sehriHour: '04',
        sehriMinutes: 21,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 21,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '18',
        date: "2023-04-10",
        day: "Monday",
        sehriHour: '04',
        sehriMinutes: 20,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 22,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '19',
        date: "2023-04-11",
        day: "Tuesday",
        sehriHour: '04',
        sehriMinutes: 19,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 22,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '20',
        date: "2023-04-12",
        day: "Wednesday",
        sehriHour: '04',
        sehriMinutes: 18,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 23,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '21',
        date: "2023-04-13",
        day: "Thursday",
        sehriHour: '04',
        sehriMinutes: 17,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 23,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '22',
        date: "2023-04-14",
        day: "Friday",
        sehriHour: '04',
        sehriMinutes: 15,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 23,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '23',
        date: "2023-04-15",
        day: "Saturday",
        sehriHour: '04',
        sehriMinutes: 14,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 24,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '24',
        date: "2023-04-16",
        day: "Sunday",
        sehriHour: '04',
        sehriMinutes: 13,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 24,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '25',
        date: "2023-04-17",
        day: "Monday",
        sehriHour: '04',
        sehriMinutes: 12,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 24,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '26',
        date: "2023-04-18",
        day: "Tuesday",
        sehriHour: '04',
        sehriMinutes: 11,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 25,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '27',
        date: "2023-04-19",
        day: "Wednesday",
        sehriHour: '04',
        sehriMinutes: 10,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 25,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '28',
        date: "2023-04-20",
        day: "Thursday",
        sehriHour: '04',
        sehriMinutes: 9,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 26,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '29',
        date: "2023-04-21",
        day: "Friday",
        sehriHour: '04',
        sehriMinutes: 8,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 26,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
    {
        id: '30',
        date: "2023-04-22",
        day: "Saturday",
        sehriHour: '04',
        sehriMinutes: 7,
        sehriTime: "AM",
        iftarHour: '06',
        iftarMinutes: 27,
        iftarTime: "PM",
        fazarHour: '06',
        fazarMinutes: 18,
        fazarTime: "PM",
    },
]

export default Time